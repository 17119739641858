<template>
  <span>
    <calendar-date
      :date="value"
      :showTime="true"
    />
  </span>
</template>

<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  components: {
    CalendarDate: () => import('@/components/calendar-date')
  },
  props: {
    value: {
      type: String
    },
    column: Column
  }
}
</script>
